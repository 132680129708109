import { getCurrentLanguage, languages } from "locales/languages"
import { last } from "lodash"

import { Gender } from "types/entity"
export const DATE_FORMAT = "DD/MM/YYYY"
export const API_DATE_FORMAT = "YYYY-MM-DD"

export const firstnameLabel = (who: "relative" | "customer") => {
  const isCustomer = who === "customer"
  const text = `Entrez ${isCustomer? 'votre': 'le'} 1er`
  return getCurrentLanguage()==="fr" ?
  <>{text} <span style={{textDecoration: "underline"}}>Prénom</span> de naissance</>:
  <>{`Enter ${isCustomer? 'your': 'the'} first name`}</>
}

const splitSentenceByCharsLength = (
  text: string,
  maxLengthByLine: number,
  words: string[] = []
): string[] => {
  const wordsFromText = text.split(" ")
  const nextWord = wordsFromText.shift()
  const newText = wordsFromText.join(" ")

  if (nextWord) {
    const lastWords = last(words)
    if (lastWords && lastWords.length + nextWord.length <= maxLengthByLine) {
      const oldLastWords = words.pop()
      words.push(`${oldLastWords} ${nextWord}`)
    } else {
      words.push(nextWord)
    }
    return splitSentenceByCharsLength(newText, maxLengthByLine, words)
  } else return words
}

export const splitByChars = (
  textToSplit: string,
  maxLengthByLine = 12
): string[] => {
  return splitSentenceByCharsLength(textToSplit, maxLengthByLine)
}

export function getTime(date: Date): string {
  let hr: number | string = date.getHours()
  let min: number | string = date.getMinutes()
  if (min < 10) min = "0" + min
  if (hr < 10) hr = "0" + hr
  return hr + ":" + min
}

export function addMinutes(date: Date, minutes: number): Date {
  return new Date(date.getTime() + minutes * 60000)
}

export const getRoundHour = (date: Date = new Date()): Date => {
  // Return the next 1/4 of the current hour
  date.setMinutes(date.getMinutes() + (5 - (date.getMinutes() % 5)))
  date.setSeconds(0)
  return date
}

export const formatNirFromMask = (mask: string | null): string =>
  mask?.length
    ? mask.split(" / ").join("").split("_").join("")
    : ""

export const isValidNir = (mask: string): boolean => {
  let nir = formatNirFromMask(mask)
  if (nir.length !== 15) return false
  // @TODO (000000000000097)
  if (/2A/.test(nir)) {
    nir = nir.replace(/2A/, "19")
  }
  if (/2B/.test(nir)) {
    nir = nir.replace(/2B/, "18")
  }

  const num = parseInt(nir.substring(0, 13))
  const key = parseInt(nir.substring(13, 15))
  if (97 - (num % 97) !== key) return false
  return true
}

export interface DateDiffResponse {
  sec: number
  min: number
  hour: number
  day: number
}
export const dateDiff = (
  date1: Date,
  date2: Date
): Partial<DateDiffResponse> => {
  const diff: Partial<DateDiffResponse> = {} // Initialisation du retour
  let tmp = date2.getTime() - date1.getTime()

  tmp = Math.floor(tmp / 1000) // Nombre de secondes entre les 2 dates
  diff.sec = tmp % 60 // Extraction du nombre de secondes

  tmp = Math.floor((tmp - diff.sec) / 60) // Nombre de minutes (partie entière)
  diff.min = tmp % 60 // Extraction du nombre de minutes

  tmp = Math.floor((tmp - diff.min) / 60) // Nombre d'heures (entières)
  diff.hour = tmp % 24 // Extraction du nombre d'heures

  tmp = Math.floor((tmp - diff.hour) / 24) // Nombre de jours restants
  diff.day = tmp

  return diff
}

export const capitalize = (str: string): string => {
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase()
}
export const century = (yy: number): number => {
  const now = new Date()
  const currentYear = parseInt(now.getFullYear().toString().substr(2, 2))
  const y = yy
  const century = y >= currentYear ? 1900 : 2000
  return century + y
}

export const timeout = (ms: number): Promise<undefined> => {
  return new Promise((resolve) => setTimeout(resolve, ms))
}

export const concatGenderAndName = (gender: Number, firstname: string, lastname: string) => {
  if (!firstname || !lastname) {
    return languages.not_informed
  }
  const fullName = `${firstname} ${lastname}`
  if (gender === Gender.FEMALE) {
    return `${languages.miss} ${fullName}`
  }
  if (gender === Gender.MALE) {
    return `${languages.mister} ${fullName}`
  }
  return fullName
}


export const formatInsString = (str: string): string => removeAccents(str).toUpperCase().trim()

export const removeAccents = (str: string): string =>
  str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');